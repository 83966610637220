.navMenu {
  display: block;
}

header {
  background-color: var(--white-color);
  box-shadow: var(--shadow-effect);
  width: 100%;
  z-index: var(--z-fixed);
  margin-bottom: 0.5rem;
}

nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 5rem;
  height: 3.5rem;
}

.logo {
  font-size: 1.5rem;
  font-weight: var(--font-weight);
}

.navList {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.navItem {
  font-size: var(--font-text);
  font-weight: var(--font-weight);
}

.navMenuActive {
  position: absolute;
  top: 60px;
  right: 0;
  background-color: var(--nav-bg);
  backdrop-filter: blur(16px);
  width: 60%;
  height: 100vh;
  transition: right 0.4s;
  z-index: var(--z-fixed);
  padding: 2rem;
}

.navMenu {
  display: block;
}

.navLink {
  color: var(--TS-Black);
  transition: color 0.3s ease;
}

.navLink:hover {
  color: var(--primary-color);
  border-bottom: 3px solid var(--primary-color);
  padding-bottom: 1.2rem;
  font-weight: 800;
}

.navLink:active {
  color: var(--primary-Color);
  border-bottom: 4px solid var(--primary-color);
  padding-bottom: 1.2rem;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

@media screen and (max-width: 900px) {
  nav {
    padding: 2rem
  }

  .hamburger {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
  }

  .navMenu {
    display: none;
  }

  .navItem {
    font-size: 1.5rem
  }

  .navMenuActive {
    display: block;
  }

  .navLink:hover {
    color: var(--TS-Black);
    border-bottom: none;
    padding-bottom: 0;
  }
}

@media screen and (min-width: 900px) {
  .hamburger {
    display: none;
  }

  .navList {
    flex-direction: row;
    column-gap: 2rem;
  }

  .navMenuActive {
    display: none;
  }
}