.teamContainer {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 2rem 5rem;
    background-color: var(--primary-bg);
    gap: 0.5rem;
  }
  
  .teamTxt {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
  }
  
  .teamMembers {
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    margin-top: 4rem;
  }
  
  .teamMember {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2rem;
    width: calc(50% - 3rem);
  }
  
  .teamImage {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: bisque;
  }
  
  .teamImg {
    width: 200px;
    height: 200px;
    border-radius: 6px;
    object-fit: cover;
  }
  
  .teamMemberInfo {
    display: flex;
    flex-direction: column;
    text-align: left;
    gap: 0.5rem;
  }
  
  .name {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
  }
  
  .title {
    color: var(--secondary-color);
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
  }
  
  .description {
    width: 100%;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    text-wrap: wrap;
  }
  
  .modal {
    position: fixed;
    top: 50%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background: white;
    padding: 20px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
    border-radius: 8px;
  }
  
  .modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.75);
  }
  
  .modalContent {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .closeButton {
    align-self: flex-end;
    background: #f44336;
    color: var(--white-color);
    border: none;
    padding: 5px 10px;
    cursor: pointer;
    border-radius: 4px;
  }
  
  .readMoreButton {
    color: var(--white-color);
    background-color: var(--primary-color);
    border: none;
    padding: 10px;
    cursor: pointer;
    border-radius: 4px;
    margin-top: 10px;
    width: 6.5rem;
  }
  
  .readMoreButton:hover {
    background-color: var(--bck-color-bg);
    border: 2px solid var(--blue-color);
    color: var(--secondary-color);
  }
  
  @media (max-width: 968px) {
    .teamMember {
      flex-direction: column;
      align-items: center;
    }
  }
  
  @media (max-width: 680px) {
    .teamMembers {
      flex-direction: column;
    }
  
    .teamImg {
      max-width: 200px;
    }
  }
  