.container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 2rem 5rem;
    background-color: var(--white-color);
    gap: 2rem;
}
.buttonsContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
}
.articleContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.article {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-wrap: wrap;
    text-align: left;
}

.articleDetail {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
}

.articleInfo {
    display: flex;
    flex-direction: row;
    align-items: start;
    gap: 0.6em;
}

.articleCategory {
    color: var(--primary-color);
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
}

.dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--circle-color);
    margin-top: 7px;
}

.articleTimeDate {
    color: var(--text-ash);
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;

}

.articleTitle p {
    font-size: 20px;
    font-weight: 600;
    line-height: 28px;
}

