.container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 2rem 5rem;
    background-color: var(--primary-bg);
    gap: 2rem;
}

.imgContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
}

.imgServices {
    width: 100%;
    height: auto;
    border-radius: 15px;
    padding-bottom: 1rem;
}

.img {
    width: 100%;
    height: auto;
}

.txtService {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1.5rem;
    color: var(--TS-Black);
    text-align: left;
    text-wrap: wrap;

}

.txtService h4 {
    font-size: 24px;
    font-weight: 700;
    line-height: 48px;
}

.txtService p {
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
}

.youtube{
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: var(--secondary-color);
}
.highlighted {
    background-color: var(--secondary-color);
    color: var(--white-color);
}
@media (max-width: 968px){
    .imgContainer{
    grid-template-columns:1fr;
    grid-template-rows: repeat(3, 1fr);
    }
}