footer {
    background-color: var(--secondary-bg);
    border: 1px solid var(--border);
    position: relative;
    text-align: left;
}

.container {
    padding: 6rem 6rem;
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.divider {
    border-top: 3px solid #E4E4E4;
    margin: 0;
}

.copyRight p {
    font-size: 1rem;
    padding-bottom: 5px
}

.bottomRight {
    display: flex;
    padding-top: 25px;
    justify-content: space-between;
}

.bottomLink {
    display: flex;
    gap: 2rem;
}

.bottomLink p {
    font-size: 0.95rem;
    font-weight: 600;
    color: #3F3F40;
}

.socials {
    display: flex;
    gap: 1rem;
}

.navigation {
    display: grid;
    grid-template-columns: repeat(4, 0.5fr);
    gap: 1rem;
}

.navChild {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: start;
}

.searchContainer {
    background-color: var(--tertiary-color);
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.searchBar {
    padding: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 0px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 0px;
    border-color: var(--white-color);
    background-color: var(--white-color);
}

.searchItems {
    display: flex;
}

.formButton {
    padding: 1rem;
    background-color: rgba(0, 129, 254, 1);
    border: none;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}

input::placeholder {
    font-size: 14px;
    font-weight: 200;
}

.buttonIcon {
    color: white;
    font-size: 1.2rem;
    font-weight: bolder;
}

.navChild h6 {
    font-weight: 700;
    font-size: 1rem;
}

.navChild p {
    font-weight: 400;
    font-size: 0.9rem;
    color: #3F3F40;
}


.link {
    text-decoration: none;
    color: var(--text-color)
}


@media screen and (max-width: 768px) {
    .container {
        padding: 4rem 4rem;
    }

    .navigation {
        grid-template-columns: repeat(2, 1fr);
        gap: 2rem;
    }
}

@media screen and (max-width: 480px) {
    footer {
        text-align: center;
    }

    .container {
        padding: 3rem 1.5rem;
    }

    .navigation {
        grid-template-columns: repeat(1, 1fr);
        gap: 2rem;
    }

    .searchContainer {
        justify-content: center;
        align-items: center;
    }

    .searchContainer h6 {
        text-align: center;
    }

    .copyRight p {
        text-align: left;
    }

    .bottomRight {
        flex-direction: column;
        align-items: center;
        gap: 1rem;
    }

    .bottomLink {
        gap: 1rem;
    }
}

@media screen and (max-width: 320px) {
    .container {
        padding: 2rem 1rem;
    }

    .searchContainer {
        padding: 0.9rem;
    }
}
