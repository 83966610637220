 .container {
    display: flex;
    flex-direction: column;
    align-items: left;
    padding: 2rem 5rem;
    background-color: var(--primary-bg);
    gap: 2rem;
}

.title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2em;
}

.hr {
    border: 1px solid #E298C1;
    width: 50%;
}

.title p {
    font-family: Mulish;
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--primary-color);
}

.txtImg {
    display: flex;
    flex: 100%;
    justify-content: space-between;
    align-items: center;
}

.txt {
    display: flex;
    flex: 80%;
    gap: 1em;
}

.txt div ul {
    list-style: none;
    text-align: left;
}

.txt div ul li {
    margin-bottom: 10px;
    background-image: url(../../Assets/Image/UlIcon.png);
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position: 0 50%;
    line-height: 1.5;
    padding-left: 30px;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;

}

.imgContainer {
    display: flex;
    flex: 20%;
    align-items: center;
    margin-left: 2rem;
}

.imgContainer img {
    max-width: 396.76px;
    transform: translateX(100%);
    transition: transform 1s ease-out;
    overflow: hidden;
}

.imgContainer img.active {
    transform: translateX(0);
}

@media (max-width: 968px){
    .imgContainer, .imgContainer img{
        display:none;
    }
}
@media (max-width: 650px){
    .txt{
        flex-direction: column;
        justify-content: center;
        gap: 0.5em;
    }
    .txt div ul li{
        padding-left: 0x;
    }
}