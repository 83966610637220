.testimonialsContainer {
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 2rem 5rem;
  gap: 0.5rem;
}

.testTxt {
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
}

.testimonialContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

.testimonialMember {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-wrap: wrap;
  text-align: left;
  margin-top: 2rem;
  transform: translateX(-100%);
}

.testimonialMember.active {
  transform: translateX(0);
  transition: transform 1s ease-out;
}

.testimonialMember1 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-wrap: wrap;
  text-align: left;
  margin-top: 2rem;
  transform: translateX(100%);
}

.testimonialMember1.active {
  transform: translateX(0);
  transition: transform 1s ease-out;
}

.testimonialMember2 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-wrap: wrap;
  text-align: left;
  margin-top: 2rem;
  transform: translateY(100%);
}

.testimonialMember2.active {
  transform: translateY(0);
  transition: transform 1s ease-out;
}

.testimonialMember3 {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  text-wrap: wrap;
  text-align: left;
  margin-top: 2rem;
  transform: translateY(100%);
}

.testimonialMember3.active {
  transform: translateY(0);
  transition: transform 1s ease-out;
}

.testimonialMember p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.testimonialMember1 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.testimonialMember2 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.testimonialMember3 p {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.testimonialAuthor {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.testimonialAuthor img {
  width: 35px;
  height: 35px;
}

.name {
  color: var(--secondary-color);
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;

}
@media(max-width:688px){
  .testimonialsContainer {
    padding: 2rem 2rem;
  }
  .testimonialContent {
    grid-template-columns: 1fr
}}