.container {
    padding: 2rem 5rem;
}

.txt {
    display: flex;
    justify-content: end;
    padding-bottom: 1.5rem;
}

.resourceLink {
    font-size: 20px;
    font-weight: 700;
    line-height: 24px;
    color: var(--secondary-color);
    text-decoration-line: underline;
}

.gridContainer {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
}

.card {
    gap: 5rem;
}

.imgResources {
    width: 100%;
    height: auto;
    border-radius: 15px;
    padding-bottom: 0.8rem;
    align-items: center;
    object-fit: cover;
}

.img {
    width: 100%;
    /* height: auto; */
    height: 100%;
    align-items: center;
    object-fit: cover;
    border: 1px solid var(--TS-Black);
    border-radius: 15px;
}

.txtContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 0.5rem;
}

.txtContainer h1 {
    color: var(--sub-black);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.txtContainer p {
    color: var(--TS-Black);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 1rem;
    text-align: left;
}

.downloadLink {
    font-size: 18px;
    font-weight: 700;
    line-height: 24px;
    color: var(--secondary-color);
    text-decoration-line: underline;
}

.eventContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 5rem;
    /* gap: 5rem; */
}

.txtLink {
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    font-size: 24px;
    font-weight: 700;
}

.txtIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
}

.txtIcon p {
    line-height: 30px;
    color: var(--sub-black);
}

.icon {
    width: 25px;
    height: 25px;
    color: #343F42;
}

.seeLink {
    line-height: 24px;
    color: var(--secondary-color);
    text-decoration-line: underline;
}

.cardGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;
    margin-top: 2rem;
}

.txtEvent {
    display: flex;
    flex-direction: column;
    color: var(--TS-Black);
}

.txtEvent h1 {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
}

.txtEvent p {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 0.2rem;
}

.eventTxt {
    font-size: 20px;
    font-weight: 400;
    line-height: 25px;
}

.seeMoreLink {
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: var(--primary-color);
}

.postEvent {
    display: flex;
    flex-direction: column;
}

.eventPost {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5rem;
}

.pastEvent {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1.5rem;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    color: #908D8E;
    background-color: transparent;
    border: none;
}

.pastEvent p:hover {
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 0.5rem
}

.eventRegister {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-items: flex-start;
    background-image: linear-gradient(#000000, #0000009E, #D9D9D900), url(../../Assets/Image/Events\ image.png);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 15px;
    margin-bottom: 2rem;
    gap: 2rem;
    padding: 5rem 2.5rem;
    color: var(--white-color);

}

.eventRegister h1 {
    font-size: 64px;
    font-weight: 700;
    line-height: 79px;
    margin-bottom: 2rem;
}

.eventRegister p {
    font-size: 24px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0.09em;
}

.eventBtn {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    margin-bottom: 2rem;
}

.active {
    border: none;
    color: var(--primary-color);
    border-bottom: 2px solid var(--primary-color);
    padding-bottom: 0.5rem;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
    cursor: pointer;
    background-color: transparent;
}

select {
    width: 180px;
    padding: 1rem;
    border-radius: 5px;
    border: 1px solid #DADADE;
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: var(--text-color);
    background-color: var(--white-color);
    cursor: pointer;
}

select:focus {
    outline: none;
}

option {
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    color: var(--text-color);
    background-color: var(--white-color);
    cursor: pointer;
    padding: 0.8rem;
}

@media screen and (max-width: 768px) {
    .gridContainer {
        grid-template-columns: repeat(2, 1fr);
    }
    .eventRegister h1 {
        font-size: 50px;
        line-height: 1.2;
        margin-bottom: 0;
    }

    .eventBtn {
        margin-bottom: 0;
    }
    .pastEvent {
        font-size: 18px;
    }
    .active {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {
    .container {
        padding: 2rem 2rem;
    }

    .gridContainer {
        grid-template-columns: repeat(1, 1fr);
    }

    .txt {
        display: flex;
        justify-content: center;
        padding-bottom: 1.5rem;
    }

    .txtContainer p {
        font-size: 14px;
    }

    .txtContainer h1 {
        font-size: 20px;
    }
    .eventRegister {
        padding: 3rem 1.5rem;
        gap: 1rem;
    }
    .eventBtn {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
    .eventRegister p {
        font-size: 20px;
    }
    select {
        width: 120px;
    }
    .pastEvent {
        font-size: 15px;
    }
    .active {
        font-size: 15px;
    }
}

@media screen and (max-width: 375px){

    .txtContainer p {
        font-size: 12px;
    }

    .txtContainer h1 {
        font-size: 18px;
    }
    .eventRegister h1 {
        font-size: 45px;
    }
}

@media screen and (max-width: 320px){

    .txtContainer p {
        font-size: 10px;
        line-height: 22px;
    }

    .txtContainer h1 {
        font-size: 16px;
    }

    .resourceLink {
        font-size: 16px;
    }

    .downloadLink {
        font-size: 16px;
    }
    .eventRegister h1 {
        font-size: 37px;
    }
    .eventRegister p {
        font-size: 18px;
    }
    .eventPost {
        gap: 1rem;
    }
}

