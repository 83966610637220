.containerBlog{
    display: flex;
    flex-direction: column;
    padding: 2rem 5rem;
    gap: 3rem;

}
.container1 {
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.containerimg {
    position: relative;
    display: inline-block;
}
.containerimg img {
   border-radius: 1.5rem;
}

.imageContent {
    position: absolute;
    background-color: hsla(0, 6%, 94%, 0.5);
    padding: 1rem;
    border-radius: 10px;
    top: 70%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 570px;
}

.imageContent h6 {
    font-size: 0.9rem;
    font-weight: 700;
    color: var(--TS-Black);
    line-height: 1.5;
}

.imageContent p {
    font-size: 0.9rem;
    font-weight: 400;
    color: var(--TS-Black);
    line-height: 1.5;
}

.imageContent h2 {
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--TS-Black);
    line-height: 1.2;
}

.blogCards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding-top: 1rem;
    column-gap: 6rem;
    row-gap: 1rem;
}

.blogCard {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    text-wrap: wrap;
    width: 250px;
    padding-top: 1rem;
}

.cardTime {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;

}

.cardTime p {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--primary-color);
}

.cardTime span {
    font-size: 0.8rem;
    font-weight: 400;
    color: var(--text-color);
}

.circle {
    border-radius: 9999px;
    width: 10px;
    background-color: var(--circle-color);
    height: 10px;
}

.cardText h4 {
    line-height: 1.5;
    font-size: 1.2rem;
    color: var(--TS-Black);
}

.blogTime {
    color: var(--text-color);
}

.container2 {
    background-color: var(--blog-bg);
    padding: 1rem;
    border-radius: 10px;
}

.articleCard {
    display: flex;
    flex-direction: column;
    gap: 1rem
}

.blogTime1 {
    color: var(--text-color);
    font-size: 0.8rem;
    font-weight: 400;
}

.articleBody {
    padding-top: 1rem;
}

.articleBody p {
    line-height: 2;
    text-wrap: wrap;
    color: var(--TS-Black);
    word-break: break-word;
    margin-bottom: 0.5rem;
}
.articleBody p a {
    color: var(--primary-color);
    font-weight: 700;
    text-decoration: underline;
}

@media screen and (max-width: 1440px) {
    .imageContent {
        width: 670px;
        top: 78%;
    }
    .articleBody p {
        font-size: 1.1rem;
    }
}

@media screen and (max-width: 1024px) {
    .containerimg img {
        width: 500px;
    }
    .imageContent {
        width: 470px;
        top: 65%;
        left: 48%;
    }

    .blogCards {
        column-gap: 1rem;
    }
    .cardText h4 {
        font-size: 1rem;
    }
    .articleBody p {
        font-size: 0.8rem;
    }
}

@media screen and (max-width: 768px) {
    .containerimg img {
        width: 600px;
    }
    .imageContent {
        width: 570px;
        top: 73%;
        left: 50%;
    }
    .blogCards {
        column-gap: 3rem;
    }
}

@media screen and (max-width: 480px) {
    .containerimg img {
        width: 400px;
    }
    .imageContent {
        width: 370px;
        top: 55%;
        left: 50%;
    }
    .blogCards {
        column-gap: 1rem;
    }

    .blogCard {
        width: 177px;
    }
    .cardTime p {
        font-size: 0.7rem;
        font-weight: 400;
        color: var(--primary-color);
    }
    
    .cardTime span {
        font-size: 0.7rem;
        font-weight: 400;
        color: var(--text-color);
    }
    .cardText h4 {
        line-height: 1.5;
        font-size: 1rem;
        color: var(--TS-Black);
    }
    .blogTime {
        font-size: 0.7rem;
    }
    .articleCard {
        width: 360px;
    }
}

@media screen and (max-width: 375px){
    .containerimg img {
        width: 350px;
    }

    .imageContent {
        width: 320px;
        top: 54%;
        left: 50%;
    }

    .imageContent h6 {
        font-size: 0.8rem;
    }

    .imageContent p {
        font-size: 0.8rem;
    }

    .imageContent h2 {
        font-size: 1.3rem;
    }

    .blogCard {
        width: 165px
    }
    .cardTime p {
        font-size: 0.6rem;
        font-weight: 400;
        color: var(--primary-color);
    }
    .cardTime span {
        font-size: 0.6rem;
        font-weight: 400;
        color: var(--text-color);
    }
    .cardText h4 {
        line-height: 1.5;
        font-size: 0.9rem;
        color: var(--TS-Black);
    }
    .articleCard {
        width: 310px;
    }
}


@media screen and (max-width: 320px) {
    .containerimg img {
        width: 300px;
    }

    .imageContent {
        width: 270px;
        top: 50%;
        left: 50%;
    }

    .imageContent h6 {
        font-size: 0.7rem;
    }

    .imageContent p {
        font-size: 0.7rem;
    }

    .imageContent h2 {
        font-size: 1.1rem;
    }

    .blogCard {
        width: 140px;
    }
    .cardTime p {
        font-size: 0.5rem;
        font-weight: 400;
        color: var(--primary-color);
    }
    .cardTime span {
        font-size: 0.5rem;
        font-weight: 400;
        color: var(--text-color);
    }
    .cardText h4 {
        line-height: 1.5;
        font-size: 0.8rem;
        color: var(--TS-Black);
    }
    .articleCard {
        width: 280px;
    }
}
