.container {
   display: flex;
   flex-direction: column;
   padding: 2rem 5rem;
}

.imageContainer {
   display: flex;
   justify-content: center;
   position: relative;
   width: 100%;
   height: 542px;
   background-image: linear-gradient(#000000, #0000009E, #D9D9D900), url(../../Assets/Image/Contact\ Us\ Image.png);
   background-size: cover;
   background-repeat: no-repeat;
   background-position: center;
   border-radius: 15px;
}

.textOverlay {
   display: flex;
   flex-direction: column;
   justify-content: center;
   color: white;
   text-align: center;
   position: absolute;
   top: 8rem;
   gap: 0.5rem;
}

.textOverlay h1 {
   font-size: 36px;
   font-weight: 700;
   line-height: 45px;
}

.textOverlay p {
   font-size: 20px;
   font-weight: 400;
   line-height: 25px;
}

.formContainer {
   display: flex;
   text-align: center;
   flex-direction: column;
   margin: -14rem 10rem 2rem 10rem;
   border-radius: 15px;
   background-color: var(--contact-bg);
   padding: 2.5rem;
   height: fit-content;
   z-index: 999;
}

.textContainer {
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   color: var(--sub-black);

}

.textContainer h1 {
   font-size: 24px;
   font-weight: 700;
   line-height: 30px;
   margin-bottom: 0.7rem;
}

.textContainer p {
   font-size: 16px;
   font-weight: 400;
   line-height: 20px;
}

form {
   display: flex;
   flex-direction: column;
   gap: 2rem;
   margin-top: 6rem;
   text-align: left;
   color: var(--TS-Black);

}

.formGroup {
   display: flex;
   flex-direction: column;
}

.formGroup label {
   font-size: 16px;
   font-weight: 400;
   line-height: 20px;
   margin: 0.5rem 0;
}

input,
textarea {
   padding: 0.7rem;
   border-radius: 5px;
   border: 1px solid #B3B4B9;
   outline: none;
   background-color: transparent;
}

.submit {
   display: flex;
   justify-content: left;
   margin-top: 1rem;

}

 .formText {
   display: flex;
   flex-direction: column;
   gap: 2em;
   margin: 6rem 10rem;
   justify-content: center;
   text-align: center;
}

.questionContainer {
   display: flex;
   flex-direction: column;
   padding: 0.5rem 1.5rem;
   /* margin: 0 auto; */
   background-color: var(--contact-bg);
   border-radius: 15px;
   /* width: 800px; */
   height: fit-content;
   text-align: left;
}

.question div {
   display: flex;
   justify-content: space-between;
   align-items: center;
   color: var(--TS-Black);
   margin: 1.5rem 0rem;
}

.question p {
   font-size: 16px;
   font-weight: 400;
   line-height: 20px;
}

.plusSign {
   width: 16px;
   height: 16px;
   cursor: pointer;
}

.question hr {
   border: 1px solid #E5E3E3
}