@import url("https://fonts.googleapis.com/css2?family=Mulish&display=swap");

:root {
  --white-color: #ffffff;
  --contact-bg: #FFF6FB;
  --black: #000;
  --sub-black: #323232;
  --TS-Black: #373435;
  --nav-bg: hsla(0, 0%, 6%, 0.2);
  --Dark-color: #0A142F;
  --text-color: #3F3F40;
  --text-ash: #8D898B;
  --primary-color: #b5529d;
  --secondary-color: #DD76AE;
  --tertiary-color: #203c860D;
  --primary-bg: #f5f5f5;
  --secondary-bg: #FCF0F9;
  --tertiary-bg: #ffe1f8;
  --bck-color-bg: #FAD5E9;
  --ash-color: #A7A1A3;
  --border: #e4e4e4;
  --Lift-Blue: #0081FE;
  --shadow-effect: 0px 4px 4px 0px rgba(188, 182, 182, 0.25);
  --z-fixed: 100;
  --font-size: 16px;
  --font-weight: 400;
  --circle-color: #D9D9D9;
  --blog-bg: #F0EEEE;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Mulish", sans-serif;
  background-color: var(--white-color);
  height: 100%;
  width: 100%;
  color: var(--TS-Black);
}